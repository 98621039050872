import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
    const fields = useMemo(
        () => [
            {
                group: 'Main Details',

                fields: [
                    {
                        name: 'name',
                        component: 'text',
                        placeholder: 'Name',
                        label: 'Name',
                        disabled: true,
                    },
                    {
                        name: 'email',
                        component: 'text',
                        placeholder: 'Email',
                        label: 'Email',
                        disabled: true,
                    },
                    {
                        name: 'phone',
                        component: 'text',
                        placeholder: 'Phone',
                        label: 'Phone',
                        disabled: true,
                    },
                    {
                        name: 'message',
                        component: 'textarea',
                        placeholder: 'Message',
                        label: 'Message',
                        disabled: true,
                    }
                ],
            },
        ],
        []
    );

    return (
        <EditView setup={setup} isFullWidth={false}>
            <AutoFields fields={fields} />
        </EditView>
    );
};

export default EditForm;
