/* global localStorage, */
import React, { useState } from 'react';
import cookies from 'js-cookie';
import {
    Flex,
    Text,
    Box,
    Button,
    Alert,
    useColorMode,
    Container,
    AlertIcon,
} from '@chakra-ui/react';
import LinkButton from '../../../components/LinkButton';
import {  useHistory } from 'react-router-dom';
import { Form, Field } from '../../../components/Form';

import { generateSlugFromSite } from '../../../helpers';
import { useHttp } from '../../../hooks';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../../../state/auth';
import supabase from "../../../../supabase";

const SubmitButton = ({ loading }) => {
    const { submit } = useFormContext();
    return (
        <Button
            size="lg"
            isFullWidth
            variant="primary"
            mb={3}
            type="submit"
            isLoading={loading}
            onClick={() => submit()}
            loadingText="Logging in securely..."
        >
            Login
        </Button>
    );
};

const Login = () => {
    const now = new Date();

    // STANDARD
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const Http = useHttp(false);
    const loginPath = process.env.REACT_APP_LOGIN_PATH;

    const onSubmit = async (data) => {
        const { data: resultData, error } = await supabase.auth.signInWithPassword({
            email: data.email,
            password: data.password,
        });



        if (!error) {
            cookies.set('token', resultData?.session.access_token);
            window.location.href = '/enquiries';
        }
    };

    return (
        <Flex align="center" justify="center" w="100vw" h="100vh" direction="column" bg="gray.100">
            <Container
                variant="Container"
                w="100%"
                maxWidth={500}
                rounded="20px"
                py="30px"
                boxShadow="md"
            >
                <Flex align="center" direction="column">
                    <Flex
                        direction="column"
                        align="center"
                        justify="center"
                        w="100%"
                        maxWidth="400px"
                    >
                        <Text as="h1" fontSize="26px" fontWeight="bold">
                            Login
                        </Text>
                        <Box py={10} px={5} w="100%">
                            <Form onSubmit={onSubmit}>
                                <Field
                                    auto={false}
                                    isFullWidth
                                    name="email"
                                    component="text"
                                    variant="filled"
                                    size="lg"
                                    placeholder="Email Address"
                                    type="email"
                                    autoFocus
                                    mb={3}
                                    rules={{ required: true }}
                                />
                                <Field
                                    auto={false}
                                    isFullWidth
                                    name="password"
                                    component="text"
                                    variant="filled"
                                    size="lg"
                                    placeholder="Password"
                                    type="password"
                                    mb={3}
                                    rules={{ required: true }}
                                />

                                <SubmitButton loading={loading} />
                            </Form>
                            <LinkButton to="forgot-password">
                                <Button variant="ghost" isFullWidth size="lg">
                                    Forgot Password
                                </Button>
                            </LinkButton>
                        </Box>
                    </Flex>

                    {error &&
                        (error.response?.data?.errors ? (
                            <Alert status="error" variant="subtle">
                                <AlertIcon />
                                {error?.response.data.errors.message}
                            </Alert>
                        ) : (
                            <Alert status="error" variant="subtle">
                                <AlertIcon />
                                Error logging in
                            </Alert>
                        ))}
                </Flex>
            </Container>
        </Flex>
    );
};
export default Login;
