const modelName = 'Enquiries'
const modelNameSingular = 'Enquiry'
const endpoint = 'enquiries'

const setup = {
   title: modelName,
   singular: modelNameSingular,
   model: modelName.replace(/\s+/g, '-').toLowerCase(),
   canDelete: true,
   canAddNew: false,
   canSearch: false,
   canUpdate: false,
   endpoint,
   accessor: 'id'
}

export default setup
