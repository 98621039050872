import React from 'react';
import { BiAtom, BiExit } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import { Flex, Icon, IconButton, Tooltip, Stack, useColorModeValue } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import SiteModal from './Modal';
import { logout } from '../../helpers';
import { use100vh } from 'react-div-100vh';
import { globalAtom, menuAtom } from '../../state/global';

const SideBar = () => {
    const globalState = useRecoilValue(globalAtom);
    const menuState = useRecoilValue(menuAtom);

    const history = useHistory();
    const height = use100vh();

    const borderColor = useColorModeValue('gray.200', 'gray.700');
    const bg = useColorModeValue('white', 'gray.900');
    const icon = useColorModeValue('primary', 'white');

    return (
        <Flex
            h={height}
            w="60px"
            direction="column"
            justify="space-between"
            align="center"
            pt="15px"
            pb="5px"
            px="8px"
            bg={bg}
            zIndex={1002}
            position={{ xs: 'fixed', md: 'relative' }}
            left={0}
            top={0}
            transform={{
                xs: menuState ? 'translateX(0%)' : 'translateX(-100%)',
                md: 'none',
            }}
            transition="0.3s all ease-in-out"
            borderRight="1px"
            borderColor={borderColor}
            display={{ base: 'none', xl: 'flex' }}
        >
            <Flex mb={20}>
                <Icon fontSize="26px" color={icon}>
                    <BiAtom />
                </Icon>
            </Flex>

            <Stack spacing="10px" flex={1} justifyContent="flex-end">
                <Tooltip label="Logout" placement="right">
                    <IconButton
                        rounded="full"
                        size="lg"
                        variant="ghost"
                        onClick={() => logout()}
                        mt="auto"
                        icon={<BiExit fontSize={20} />}
                    />
                </Tooltip>
            </Stack>
        </Flex>
    );
};

export default SideBar;
