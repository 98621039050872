import { logout } from '../../helpers';
import cookies from "js-cookie";

const AuthChecker = ({ children }) => {
    const token = cookies.get('token');

    if (!token) {
        logout();
    }

    return children;
};

export default AuthChecker;
