import React, { Suspense } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormLabel, Box, Text as UIText, Tag, Skeleton } from '@chakra-ui/react';

import TimePicker from '../../Fields/TimePicker';

// import { List } from '../../Fields/List'

import ColourPicker from '../../Fields/ColourPicker';
import Text from '../../Fields/Text';
import TextArea from '../../Fields/TextArea';
import Date from '../../Fields/DatePicker';
import RichText from '../../Fields/RichText';
import File from '../../Fields/File';
import Switch from '../../Fields/Switch';
import { Repeater } from '../../Fields/Repeater';
import Select from '../../Fields/Select';
import NativeSelect from '../../Fields/NativeSelect';
import Html from '../../Fields/Html';
import Number from '../../Fields/Number';
import Mask from '../../Fields/Mask';

const Field = (props) => {
    const {
        component,
        label,
        name,
        isRequired,
        defaultValue,
        guidance,
        help,
        rules,
        auto = false,
        showField = true,
        isConditional,
        isRepeater,
        noMargin,
        ...rest
    } = props;
    const { control } = useFormContext();

    const getField = (props) => {
        switch (component) {
            case 'text':
                return <Text {...props} />;
            case 'html':
                return <Html {...props} />;
            case 'date':
                return <Date {...props} />;
            case 'time':
                return <TimePicker {...props} />;
            case 'nativeSelect':
                return <NativeSelect {...props} />;
            case 'select':
                return <Select {...props} />;
            case 'textarea':
                return <TextArea {...props} required={null} />;
            case 'richtext':
                return <RichText {...props} />;
            case 'switch':
                return <Switch {...props} />;
            case 'file':
                return <File {...props} />;
            case 'colour':
                return <ColourPicker {...props} />;
            case 'number':
                return <Number {...props} />;
            case 'mask':
                return <Mask {...props} />;

            /* eslint-disable */
            default:
                const Comp = component;
                return <Comp {...props} />;
        }
    };

    return component === 'repeater' ? (
        <Repeater name={name} {...props} />
    ) : showField ? (
        <Controller
            name={name}
            defaultValue={defaultValue ? defaultValue : ''}
            rules={rules}
            control={control}
            render={({ field: { onChange, onBlur, value, name }, formState: { errors } }) => (
                <Box
                    transition="all 0.3s ease-in-out"
                    p={isRepeater ? '7px' : '0px'}
                    mb={!auto ? (noMargin ? 0 : '15px') : isRepeater ? 0 : noMargin ? 0 : '15px'}
                    w="100%"
                    value={value}
                >
                    <Suspense fallback={<Skeleton />}>
                        <FormControl
                            mb={isRepeater ? 0 : noMargin ? 0 : 3}
                            isInvalid={errors[name]}
                            isRequired={rules && rules.required}
                        >
                            {label && (
                                <FormLabel
                                    htmlFor={name}
                                    fontSize="11px"
                                    fontWeight="semibold"
                                    color="gray.600"
                                    mb="3px"
                                >
                                    {label}{' '}
                                </FormLabel>
                            )}

                            {getField({
                                onChange,
                                onBlur,
                                value,
                                name,
                                isRepeater,
                                defaultValue,
                                ...rest,
                            })}

                            {help && (
                                <UIText fontSize="11px" color="gray.500" mt="4px">
                                    {help}
                                </UIText>
                            )}

                            {errors[name] && (
                                <Tag size="sm" colorScheme="red">
                                    {errors[name].message ? errors[name].message : 'Required'}
                                </Tag>
                            )}
                        </FormControl>
                    </Suspense>
                </Box>
            )}
        />
    ) : null;
};

export default Field;
