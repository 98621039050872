/* eslint-disable */
const links = [
    {
        group: "Enquiries",
        link: "/enquiries",
        items: [],
    },
 ]

 export default links
