import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';
import setup from './setup';

const extraMenu = () => null;

const List = () => {
    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            id: 'id',
            Cell: ({ row }) => (
                <LinkButton
                    to={`${setup.model}/edit/${row.original[setup.accessor]}`}
                >
                    <Button
                        variant='link'
                        variantColor='secondary'
                        rounded='md'
                    >
                        {row.original.name}
                    </Button>
                </LinkButton>
            ),
        },
        {
            Header: 'Email',
            accessor: 'email',
        },
        {
            Header: 'Phone',
            accessor: 'phone',
        },
        {
            Header: 'Date',
            accessor: 'created_at',
            Cell: ({ value }) => new Date(value).toUTCString(),
        },
    ];

    return (
        <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />
    );
};

export default List;
