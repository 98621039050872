import React from 'react'
import Page from '../components/Page'
import AuthChecker from '../components/AuthChecker'
import { Route } from 'react-router-dom'

const PublicRoutes = ({ component: Component, title, ...rest }) => {
   return (
      <Route
         {...rest}
         render={(props) => (
            <>
               <Page title={title} auth={false}>
                  <Component {...props} />
               </Page>
            </>
         )}
      />
   )
}

export default PublicRoutes
