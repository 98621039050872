import React, { memo, useRef } from 'react';
import {
    Box,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Button,
    Image,
    Flex,
    useColorMode,
    Icon,
} from '@chakra-ui/react';
import { use100vh } from 'react-div-100vh';
import { menuAtom } from '../../state/global';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Link, useRouteMatch } from 'react-router-dom';
import { FiArrowUpRight } from 'react-icons/fi';
import { checkPermissions } from '../../helpers';
import { useOutsideClick } from '../../hooks';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useContext } from 'react';
import { NucleusContext } from '../../Core';
import { authAtom } from '../../state/auth';

const MenuLinkButton = memo(({ to, children, target, leftIcon, isChild, ...rest }) => {
    const match = useRouteMatch({
        path: to,
        exact: true,
    });

    return to ? (
        <Link to={to ? to : null} style={{ width: '100%' }}>
            <Button
                variant={match ? 'solid' : 'ghost'}
                isFullWidth
                justifyContent="flex-start"
                rightIcon={target && <FiArrowUpRight />}
                fontWeight={isChild ? 'normal' : 'semibold'}
                opacity={isChild ? 0.7 : 1}
                leftIcon={
                    leftIcon ? (
                        <Icon fontSize="20px" color={match ? 'primary' : 'menuIconColour'}>
                            {leftIcon}
                        </Icon>
                    ) : null
                }
                size="sm"
                color={match && 'primary'}
                mb={isChild ? '0px' : '5px'}
                {...rest}
            >
                {children}
            </Button>
        </Link>
    ) : (
        <Button
            variant={match ? 'solid' : 'ghost'}
            isFullWidth
            justifyContent="flex-start"
            rightIcon={target && <FiArrowUpRight />}
            fontWeight={isChild ? 'normal' : 'semibold'}
            opacity={isChild ? 0.7 : 1}
            leftIcon={
                leftIcon ? (
                    <Icon fontSize="20px" color={match ? 'primary' : 'menuIconColour'}>
                        {leftIcon}
                    </Icon>
                ) : null
            }
            size="sm"
            color={match && 'primary'}
            mb={isChild ? '0px' : '5px'}
            {...rest}
        >
            {children}
        </Button>
    );
});

const Menu = () => {
    const { links } = useContext(NucleusContext);
    const height = use100vh();
    const auth = useRecoilValue(authAtom);
    const [_menu, setMenu] = useRecoilState(menuAtom);
    const menu = useRef();

    useOutsideClick(menu, () => setMenu(false));

    const hasPermission = (permissions) => {
        return true;
    };

    const getTransform = _menu ? 'translateX(0px)' : 'translateX(-100%)';

    return (
        <React.Fragment>
            <Box
                h={height}
                maxHeight={height}
                w="200px"
                minWidth="200px"
                py={2}
                px={3}
                overflowY="scroll"
                left={0}
                top={0}
                zIndex={{ base: 9999, xl: 900 }}
                transform={{
                    base: getTransform,
                    xl: 'none',
                }}
                transition="0.3s all ease-in-out"
                pos={{
                    base: 'fixed',
                    xl: 'relative',
                }}
                bg="white"
                ref={menu}
            >
                <Box>
                    {links &&
                        links.map((group, groupIndex) =>
                            group.items && group.items.length === 0
                                ? hasPermission(group.permissions ? group.permissions : []) && (
                                      <MenuLinkButton
                                          key={`group--${groupIndex}`}
                                          to={group.link}
                                          target={group.target}
                                      >
                                          {group.group}
                                      </MenuLinkButton>
                                  )
                                : hasPermission(group.permissions ? group.permissions : []) && (
                                      <Accordion
                                          key={`${group.group}-${groupIndex}`}
                                          collapsible
                                          multiple
                                          borderColor="transparent"
                                          allowToggle
                                      >
                                          <AccordionItem>
                                              <AccordionButton p={0}>
                                                  <MenuLinkButton
                                                      as="div"
                                                      to={group.link}
                                                      target={group.target}
                                                      mb={0}
                                                      align="center"
                                                  >
                                                      <Flex w="100%" align="center">
                                                          {group.group}
                                                          <Icon ml="auto" fontSize="22px">
                                                              <MdKeyboardArrowRight />
                                                          </Icon>
                                                      </Flex>
                                                  </MenuLinkButton>
                                              </AccordionButton>
                                              <AccordionPanel px={0}>
                                                  {group.items &&
                                                      group.items.map(
                                                          (item, index) =>
                                                              hasPermission(
                                                                  item.permissions
                                                                      ? item.permissions
                                                                      : [],
                                                              ) && (
                                                                  <MenuLinkButton
                                                                      target={item.target}
                                                                      to={item.href}
                                                                      key={`${item.title}-${index}-${groupIndex}`}
                                                                      isChild
                                                                  >
                                                                      {item.title}
                                                                  </MenuLinkButton>
                                                              ),
                                                      )}
                                              </AccordionPanel>
                                          </AccordionItem>
                                      </Accordion>
                                  ),
                        )}
                </Box>
            </Box>
            <Box
                w="100vw"
                h="100vh"
                bg="rgba(0,0,0,0.2)"
                position="fixed"
                zIndex={8000}
                top={0}
                left={0}
                content="' '"
                display={_menu ? 'block' : 'none'}
            />
        </React.Fragment>
    );
};

export default Menu;
